import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngxs/store';
import { TRoleName } from 'apps/early-stage-office/src/app/core/models/role.interface';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';
import { ModalsService } from '../../../utility-modules/modals/services/modals.service';
import { SettingsModalComponent } from '../settings-modal/settings-modal.component';
import { NgIf, NgFor } from '@angular/common';

type TInstruction = {
    imageName: string;
    preTitle: string;
    title: string;
    url: string;
    roles: TRoleName[];
};

@Component({
    selector: 'es-support-modal',
    templateUrl: './support-modal.component.html',
    styleUrls: ['./support-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor],
})
export class SupportModalComponent implements OnInit {
    private allInstructions: TInstruction[] = [
        {
            imageName: 'Instrukcje_franczyzobiorca',
            preTitle: 'Early Stage OFFICE',
            title: 'Instrukcja dla Franczyzobiorcy i Sekretariatu',
            url: 'https://earlystage.atlassian.net/servicedesk/customer/portal/7/article/2135818295',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
        },
        {
            imageName: 'Instrukcje_umowy',
            preTitle: 'Early Stage OFFICE',
            title: 'Umowy Online',
            url: 'https://earlystage.atlassian.net/servicedesk/customer/portal/7/article/2145091585',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT', 'ROLE_PAYMENTS'],
        },
        {
            imageName: 'Instrukcje_platnosci',
            preTitle: 'Early Stage OFFICE',
            title: 'Płatności',
            url: 'https://earlystage.atlassian.net/servicedesk/customer/portal/7/article/2131296275',
            roles: ['ROLE_SUPER_ADMIN', 'ROLE_PAYMENTS'],
        },
        {
            imageName: 'Instrukcje_metodyk',
            preTitle: 'Early Stage OFFICE',
            title: 'Instrukcja dla Metodyka',
            url: 'https://earlystage.atlassian.net/servicedesk/customer/portal/7/article/2233335812',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT', 'ROLE_DOS'],
        },
        {
            imageName: 'Instrukcje_lektor',
            preTitle: 'Early Stage OFFICE',
            title: 'Instrukcja dla Lektora',
            url: 'https://docs.google.com/presentation/d/1Vhi0vMiv9S5IcBfvw7_muU42MGbEGgXi7ySwwHUU2jg/edit#slide=id.p1',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_DOS', 'ROLE_PAYMENTS', 'ROLE_LECTOR', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
        },
        {
            imageName: 'Instrukcje_asystent',
            preTitle: 'Asystent Filii',
            title: 'Instrukcja',
            url: 'https://earlystage.atlassian.net/servicedesk/customer/portal/7/article/2236907521',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
        },
        {
            imageName: 'Instrukcje_music',
            preTitle: 'Early Stage MUSIC',
            title: 'Instrukcja dla pracowników',
            url: 'https://docs.google.com/presentation/d/14-715qtVKvO37epavPtbJGxy1wVsN62ujW-9qqY1SpM/edit#slide=id.g2a37522e0c6_1_0',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT', 'ROLE_LECTOR'],
        },
    ];

    public instructions: TInstruction[] = [];

    public enableSlackLink: boolean = false;

    constructor(
        private store: Store,
        private modals: ModalsService
    ) {}

    public ngOnInit(): void {
        this.enableSlackLink = this.store.selectSnapshot(ProfileState.someRole(['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT']));

        const roles = this.store.selectSnapshot(ProfileState.roles);

        if (roles && roles.length > 0) {
            for (const instruction of this.allInstructions) {
                const hasSomeRole = roles.some(r => instruction.roles.some(rName => r.role === rName));

                if (hasSomeRole) {
                    this.instructions.push(instruction);
                }
            }
        }
    }

    public openSettingsModal(): void {
        this.modals.open(SettingsModalComponent, {
            style: 'anchored',
            anchorType: 'right',
            width: 500,
        });
    }
}
